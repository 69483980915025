.App {
  text-align: center;
}

.Authenticator {
  text-align: center;
  padding: 5%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.custom-btn {
  background: #fff;
  color: black;
  font-weight: bold;
  border: 2px solid lightskyblue;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
  border-bottom: 2px solid lightskyblue;
}

.space-on-bottom {
  margin-bottom: 20px;
}

.arrow-btn {
  background: #fff;
  color: black;
  font-weight: bold;
  border: 2px solid lightskyblue;
  border-radius: 3px;
  border-bottom: none;
  padding: 5px 10px;
  text-transform: uppercase;
}

.spotify-btn {
  background: #fff;
  color: #1DB954;
  
  border: 1px solid #1DB954;
  font-size: 40%;
  border-radius: 3px;
  padding: 0px 10px;
  text-transform: uppercase;
}

.apple-btn {
  background: #fff;
  color: silver;
  
  border: 1px solid silver;
  font-size: 40%;
  border-radius: 3px;
  padding: 0px 10px;
  text-transform: uppercase;
}

.arrow-btn:hover {
  background-color:lightskyblue;
  color: white;
}

.custom-page-display {
  background: #fff;
  color: black;
  font-weight: bold;
  border: 2px solid lightskyblue;
  border-bottom: none;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.custom-dropdown-btn {
  background: #fff;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.custom-dropdown-btn:hover {
  background-color:lightskyblue; 
  color: white;
}

.mt-1 {
  margin-top: 0.25rem !important;
  text-align: center;
  
  border: 2px solid lightskyblue;
}

.final-row {
  margin-bottom: 1rem !important;
}

.mt-2 {
  margin-top: 0.25rem !important;
}

.input-button {
  background-color: lightskyblue;
  border: none;
  color: white;
  padding: 12px 32px;
  text-decoration: none;
  margin: 1% 2px;
  cursor: pointer;
  border-radius: 10px;
}

.input-box {
  padding: 12px 32px;
  margin: 1% 2px;
  border-bottom-color: lightskyblue;
  border-top: none;
  border-left: none;
  border-right: none;
  text-emphasis-color: rgb(170, 51, 21);
  text-align: center;
  color: lightskyblue;
}

.name-text {
  margin: 1% 2px;
  color: lightskyblue;
}

.trait-text {
  color: black;
}

.frag-style {
  border: none;
}

.table-body {
  border-collapse: collapse;
}

.spotify-name-text {
  margin: 1% 2px;
  color: #1DB954
}

.apple-name-text {
  margin: 1% 2px;
  color: silver;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
